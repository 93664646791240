import { motion } from "framer-motion";
import React from "react";

import AssignmentItem from "@/components/content-types/AssignmentItem/AssignmentItem";
import AudioItem from "@/components/content-types/AudioItem/AudioItem";
import HomeBlog from "@/components/content-types/BlogItem/HomeBlog";
import BriefItem from "@/components/content-types/BriefItem/BriefItem";
import ChatExampleNew from "@/components/content-types/ChatExampleItem/ChatExampleNew";
import ForumPost from "@/components/content-types/ForumPost/ForumPost";
import InstagramPost from "@/components/content-types/InstagramPost/InstagramPost";
import VideoItem from "@/components/content-types/VideoItem/VideoItem";
import { TagSummary } from "@/types/api/tag.types";
import { Category } from "@/types/buddyProgramTypes";
import parseImageURL2 from "@/utils/parseImageURL2";
import parseVideoURL2 from "@/utils/parseVideoURL2";
import { getContentPath } from "@/utils/url";

interface ContentItemProps {
  data: { type: string; item: any };
}

const ContentItem = ({ data }: ContentItemProps) => {
  switch (data.type) {
    case "videos":
      return (
        <motion.div
          className={`grid-item`}
          style={{
            backgroundColor: "var(--color-input-placeholder)",
            borderRadius: "8px",
          }}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <VideoItem
            poster={parseImageURL2(data.item?.video_cover_image)}
            title={data.item?.title}
            src={parseVideoURL2(data.item?.video_file)}
            subtitle={data.item?.subtitle}
            className="max-h-[624px] min-h-[624px] rounded-[8px]"
            isHomepageVideo={true}
            tags={data.item?.tags?.map((tag: TagSummary) => tag.name) ?? []}
            categories={
              data.item?.categories?.map((c: Category) => c.name) ?? []
            }
            autoPlay
          />
        </motion.div>
      );
    case "chats_item":
      return (
        <motion.div
          className={`grid-item`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <ChatExampleNew
            convo={data.item?.chat_buddy}
            buddy={data.item?.buddy}
            name={data.item?.name}
            age={data.item?.age}
            profilePic={data.item?.profile_image?.id}
          />
        </motion.div>
      );
    case "instagram":
      return (
        <motion.div
          className={`grid-item with-bg-image bg-blue rounded-lg lg:max-w-lg`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <InstagramPost embedCode={data.item?.embedCode} />
        </motion.div>
      );
    case "letter":
      return (
        <motion.div
          className={`grid-item letter col-span-2`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <a href={`/open-brieven/${data.item.slug}`} className="letter-post">
            <BriefItem
              categories={
                data.item.categories?.map((c: Category) => c.name) ?? []
              }
              key={data.item.id}
              title={data.item.title}
              description={data.item.description}
              content={data.item.content}
              imgSrc={parseImageURL2(data.item.image)}
              fileSrc={`/open-brieven/${data.item.slug}`}
              imgHeight={180}
              className={"bordered-no-shadow h-full"}
              tags={data.item.tags?.map((tag: TagSummary) => tag.name) ?? []}
            />
          </a>
        </motion.div>
      );
    case "forum":
      // Choose the two shortest categories to display so that the content can be properly displayed
      const categories =
        data.item?.categories
          ?.sort((a: any, b: any) => a.name.length - b.name.length)
          .slice(0, 2)
          .map((cat: any) => cat.name) ?? [];

      return (
        <motion.div
          className={`grid-item forum-card`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <a
            href={`/forum/${data.item?.slug}`}
            className="forum-post home-forum-post relative"
          >
            <div className="forum-content">
              <div className="front relative">
                <ForumPost
                  id={data.item?.id}
                  showButton
                  buttonUrl={`/forum/${data.item?.slug}`}
                  truncateContent
                  gender={data.item?.user_gender}
                  age={data.item?.user_age}
                  name={data.item?.user_name}
                  postDate={new Date(data.item.date_created)}
                  tagsHome={categories}
                  tagsHomeInverse={
                    data.item?.tags?.map((tags: TagSummary) => tags.name) ?? []
                  }
                  title={
                    data.item?.title ?? "Titel moet in CMS worden ingevoerd"
                  }
                  likesCount={data.item?.likes_count}
                  comments={data.item?.comments?.length}
                  commentsCount={data.item?.comments?.length || 0}
                  className="forum-post"
                  content={data.item?.content}
                  tagColor={"var(--color-pink)"}
                  isHomepage
                />
              </div>
              <div className="back absolute top-0 z-10">
                <ForumPost
                  id={data.item?.id}
                  showButton
                  buttonUrl={`/forum/${data.item?.slug}`}
                  truncateContent
                  gender={data.item?.user_gender}
                  age={data.item?.user_age}
                  name={data.item?.user_name}
                  postDate={new Date(data.item.date_created)}
                  tagsHomeBack={categories}
                  tagsHomeInverseBack={
                    data.item?.tags?.map((tags: TagSummary) => tags.name) ?? []
                  }
                  title={
                    data.item?.title ?? "Titel moet in CMS worden ingevoerd"
                  }
                  likesCount={data.item?.likes_count}
                  comments={data.item?.comments?.length}
                  commentsCount={data.item?.comments?.length || 0}
                  className="forum-post"
                  content={data.item?.content}
                  button={true}
                  tagColor={"var(--color-teal)"}
                  isBackSide
                  isHomepage
                />
              </div>
            </div>
          </a>
        </motion.div>
      );
    case "audio_item":
      return (
        <motion.div className={`grid-item spotify-post`}>
          <AudioItem
            spotify_link={data.item.spotify_link}
            audio_link={""}
            spotify_full_screen={true}
            type={"audio"}
            title={data.item.title}
            likes_count={data.item.likes_count}
            categories={data.item.categories}
          />
        </motion.div>
      );
    case "blog_quote":
      return (
        <motion.div
          className={`grid-item`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <a
            href={`/${getContentPath(data.item?.type)}/${data.item?.slug}`}
            className={"h-full"}
          >
            <HomeBlog
              title={data.item?.title}
              categories={
                data.item?.categories?.map((tag: TagSummary) => tag.name) ?? []
              }
              author={data.item?.author}
              buttonColor={"var(--color-white)"}
              content={
                data.item?.content === undefined ? "" : data.item?.content
              }
              imgSrc={parseImageURL2(data.item?.imageId)}
              tags={data.item?.tags?.map((tag: TagSummary) => tag.name) ?? []}
              postDate={new Date(data.item?.date_created)}
              likesCount={data.item?.likes_count}
              commentsCount={data.item?.comments?.length}
              id={data.item?.id}
              className={`h-full`}
              type={data.item?.type}
            />
          </a>
        </motion.div>
      );
    case "assignments":
      return (
        <motion.div
          className={`grid-item`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <AssignmentItem assignment={data.item} />
        </motion.div>
      );
    default:
      return null;
  }
};

export default ContentItem;
